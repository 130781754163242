import React, { useState, useEffect } from 'react';
import { CRow, CCol, CTooltip, CCard, CCardBody, CCardHeader, CCollapse } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';

// UTILS
import ComponentsUtils from '../../utils/componentsUtils';

// COMPONENTS
import UiButton from './UiButton';

let localFilterGlobal = null;

function UiCard(props) {
  const [filter, setFilter] = useState({});
  const [isNewFilter, setIsNewFilter] = useState(true);
  const [collapsible, setCollapsible] = useState((props.collapsible !== null && props.collapsible !== undefined) ? props.collapsible : true);
  const [cardIsOpen, setCardIsOpen] = useState(props.isOpen ? props.isOpen : false);
  const [localFilter, setLocalFilter] = useState();

  useEffect(() => {
    setCollapsible((props.collapsible !== null && props.collapsible !== undefined) ? props.collapsible : collapsible);
    if (props.localFilter) {
      let cloneLocalFilter = props.localFilter;
      cloneLocalFilter.mandatory = false;
      if (props.localFilter.reducer && props.apiReducer && props.apiReducer[props.localFilter.reducer]) {
        cloneLocalFilter.values = props.apiReducer[props.localFilter.reducer].data;
      }
      if (isNewFilter && (cloneLocalFilter.type === 'checkbox-group' || cloneLocalFilter.type === 'chip' || cloneLocalFilter.type === 'multiselect')) {
        const values = (cloneLocalFilter.values) ? cloneLocalFilter.values : [];
        setFilter({ [cloneLocalFilter.name]: values });
        setIsNewFilter(false);
      }
      setLocalFilter(cloneLocalFilter);
    }
  }, [props])

  useEffect(() => {
    localFilterGlobal = cloneDeep(localFilter);
    if (localFilterGlobal && localFilterGlobal.type === 'checkbox-group') {
      localFilterGlobal.inline = true;
    }
  }, [filter, isNewFilter, collapsible, cardIsOpen, localFilter])

  function toggleCollapse() {
    if (props.onToggleCard !== undefined && props.onToggleCard !== null && props.cardId !== undefined && props.cardId !== null) {
      props.onToggleCard(props.cardId);
    } else {
      setCardIsOpen(!cardIsOpen);
    }
  }

  function onLocalFilter(event) {
    setFilter({
      [event.target.name]: event.target.value
    })

    props.onLocalFilter(event);
  }

  function onClickHeaderButtons(callback) {
    if (!cardIsOpen) {
      toggleCollapse();
    }
    if (props[callback]) {
      eval(`props.${callback}()`);
    }
  }
  return (
    <I18n ns="translations">
      {t => (
        <div className={props.className}>
          <CCard tag={props.tag} style={props?.styleCard}>
            <CCardHeader className={props.warning ? 'UiSectionHeader_withWarning' : ''}>
              <CRow>
                <CCol className="UiCard__header" style={{ width: '100%' }}>
                  {props.icon && (
                    <div className="UiCard__header__icon mr-2">
                      <CIcon icon={props.icon} />
                    </div>
                  )}
                  <div className="UiCard__header__text">
                    <span role="presentation" className={collapsible ? 'toggle-collapse' : ''} onClick={collapsible ? toggleCollapse.bind(this) : null}>
                      <strong>{t(props.title)}</strong>
                    </span>
                    {props.tooltipMsg && (
                      <span>
                        <CTooltip placement="top" content={t(props.tooltipMsg)}>
                          <CIcon icon="cis-info-circle" size="sm" />
                        </CTooltip>
                      </span>
                    )}
                    <br />
                    {t(props.subtitle)}
                  </div>
                  <div className="UiCard__header__content">
                    {localFilter && (localFilter.type !== 'checkbox-group') && (
                      <div className="UiCard__header__content__filter">
                        {ComponentsUtils.renderByType(false, false, true, localFilter, filter, onLocalFilter.bind(this))}
                      </div>
                    )}
                    <div className="UiCard__header__content__actions">
                      {props.exportable && (
                        <UiButton icon="cis-cloud-download" label={t('Common.export')} onClick={onClickHeaderButtons.bind(this, 'onExport')} className='grid-filter-button' />
                      )}
                      {props.linkable && (
                        <UiButton icon="cis-pencil" style={{ backgroundColor: props.editBtnBackground, color: props.editBtnColor, fontWeight: 'bold' }}
                          label={(props.editBtnLabel) ? props.editBtnLabel : t('Common.edit')} onClick={onClickHeaderButtons.bind(this, 'onEdit')} className='grid-filter-button' />
                      )}
                      {props.insertable && (
                        <CRow>
                          <CCol>
                            <UiButton icon="cis-plus" label={t('Common.add')} disabled={props.disabled} onClick={onClickHeaderButtons.bind(this, 'onClick')} className='grid-filter-button' />
                          </CCol>
                        </CRow>
                      )}
                      {props.deletable && (
                        <UiButton icon="cis-trash" color="danger" label={t('Common.delete')} disabled={props.disabled} onClick={onClickHeaderButtons.bind(this, 'onDelete')} className='grid-filter-button' />
                      )}
                    </div>
                  </div>
                  {collapsible && (
                    <span className="UiCard__header__toggle toggle-collapse">
                      <CIcon icon="cis-chevron-bottom" role="presentation" className={`ml-3 collapsible-angle-icon${cardIsOpen ? ' opened' : ''}`} onClick={toggleCollapse.bind(this)} />
                    </span>
                  )}
                </CCol>
              </CRow>
              <CRow>
                {localFilter && (localFilter.type === 'checkbox-group') && (
                  <div className="flexComponentNoGrow">
                    {ComponentsUtils.renderByType(false, false, true, localFilter, filter, onLocalFilter.bind(this))}
                  </div>
                )}
              </CRow>
            </CCardHeader>
            <CCollapse visible={collapsible ? cardIsOpen : true}>
              <CCardBody>{props.children}</CCardBody>
            </CCollapse>
          </CCard>
        </div>
      )}
    </I18n>
  );
}

export default UiCard;
