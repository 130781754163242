import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CFormLabel, CFormInput, CTooltip } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { toast } from 'react-toastify';
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import PropTypes from 'prop-types';

// STORE
import ApplicationStore from '../../redux/store';

// SERVICES
import SiteMapService from '../../services/siteMapService';
import TableMapService from '../../services/tableMapService';

// COMPONENTS
import UiLoading from './UiLoading';
import UiCreationsModal from './UiCreationsModal';

import { userType } from '../../types';
import ObjectsUtils from '../../utils/objectsUtils';
import ConstantsService from '../../services/constantsService';

const propTypes = {
  apiReducer: PropTypes.object,
  currentUser: userType,
  disabled: PropTypes.bool,
  envContext: PropTypes.object,
  envCreationRoute: PropTypes.string,
  fieldDisplayed: PropTypes.string,
  fieldReturned: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onCreateSearchChange: PropTypes.func,
  onFocus: PropTypes.func,
  onSearchChange: PropTypes.func,
  onValueChanged: PropTypes.func,
  placeholder: PropTypes.string,
  reducer: PropTypes.string,
  required: PropTypes.bool,
  route: PropTypes.string,
  title: PropTypes.string,
  tooltipMsg: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  values: PropTypes.array,
  invalid: PropTypes.bool,
  feedbackInvalid: PropTypes.string,
  bindValue: PropTypes.string,
};

class UiAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      creationModel: null,
      touched: false,
      disabled: false,
    };

    this.UiAutocompleteInput = React.createRef();

    this.siteMap = SiteMapService.getSiteMap();

    this.idleTimeout = null;

    this.lastSearch = null;
    this.lastValue = undefined;

    window.addEventListener('scroll', this.setAutocompleteOptionsPosition.bind(this));
    window.addEventListener('keydown', this.handleKeyPress.bind(this), { passive: false });
    window.addEventListener('mousedown', this.handleCloseOnMouseOut.bind(this), { passive: false });
  }

  componentWillUnmount() {
    if (this.idleTimeout !== null) clearTimeout(this.idleTimeout);
    window.removeEventListener('scroll', this.setAutocompleteOptionsPosition.bind(this));
    window.removeEventListener('keydown', this.handleKeyPress.bind(this));
    window.removeEventListener('mousedown', this.handleCloseOnMouseOut.bind(this));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = cloneDeep(prevState);
    newState.forced = false;
    newState.disabled = nextProps.disabled || prevState.modalAdd;
    const oldVal = (prevState.currentValue && (prevState.currentValue.length > 0 || parseInt(prevState.currentValue) > 0) || (isObject(prevState.currentValue) && Object.keys(prevState.currentValue).length > 0)) ? prevState.currentValue : null;
    const newVal = (nextProps.value && (nextProps.value.length > 0 || parseInt(nextProps.value) > 0) || (isObject(nextProps.value) && Object.keys(nextProps.value).length > 0)) ? nextProps.value : null;
    let values = null;
    if ((!newState.values && nextProps.values) || !isEqual(nextProps.values, newState.values) || newVal !== oldVal) {
      values = (nextProps.nosort && nextProps.values) ? nextProps.values : (nextProps.values) ? nextProps.values : null;
      newState.values = values;
      //if (nextProps.reducer && nextProps.reducer.indexOf('customer_fiscal_contacts') === 0) console.log(nextProps.reducer + " new Values " + ((values) ? values.length : '0'));
    }
    // if (newVal !== oldVal || newState.id !== nextProps.id) {
    if ((isObject(newVal) && isObject(oldVal) ? !isEqual(newVal, oldVal) : newVal !== oldVal) || newState.id !== nextProps.id) {
      //if (nextProps.reducer && nextProps.reducer.indexOf('customer_fiscal_contacts') === 0) console.log("PIPPOOOOOO label:" + nextProps.label + " newVal:" + newVal + " - oldVal:" + oldVal);
      let currentValue = null;
      let currentDisplayedValue = (newState.id === nextProps.id) ? newState.currentDisplayedValue : '';
      let forced = (newState.values !== null && !isEqual(nextProps.values, newState.values));
      if (nextProps.values) {
        if (newVal) {
          if (nextProps.fieldDisplayed && nextProps.fieldReturned) {
            const objValue = nextProps.values.find((obj) => '' + obj[nextProps.fieldReturned] === '' + newVal);
            if (objValue) {
              currentValue = newVal;
              currentDisplayedValue = (objValue[nextProps.fieldDisplayed]) ? objValue[nextProps.fieldDisplayed] : '';
              forced = false;
            }
          } else {
            currentValue = newVal;
            currentDisplayedValue = !isObject(newVal) ? newVal.toString() : newVal[nextProps.fieldDisplayed];
            forced = false;
          }
        } else {
          currentValue = newVal;
          currentDisplayedValue = null;
          forced = false;
        }
      }
      //if (nextProps.reducer && nextProps.reducer.indexOf('customer_fiscal_contacts') === 0) console.log(nextProps.reducer + " newVal !== oldVal -> " + newVal + " forced = " + forced);
      newState = {
        id: nextProps.id,
        oldCurrentDisplayedValue: currentDisplayedValue,
        currentDisplayedValue,
        currentValue,
        forced,
        values,
        showIt: newState.showIt,
        modalAdd: false,
        errorsModal: null,
        currentSuggestion: 0,
        // invalid: (nextProps.required ? (nextProps.invalid ? nextProps.invalid : (!newVal)) : null),
        disabled: newState.disabled,
      };
    }

    newState.loading = !!nextProps.value && !nextProps.values && (nextProps.route || nextProps.reducer);

    return newState;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if ((!this.props.values || this.props.values.length === 0) && (this.props.reducer && this.props.apiReducer && this.props.apiReducer[this.props.reducer] && this.props.apiReducer[this.props.reducer].status === 'INITIALIZED') && (this.lastSearch !== null || this.state.disabled && !nextState.disabled)) {
      this.lastSearch = (this.state.disabled && !nextState.disabled) ? -1 : null;
      //if (this.props.reducer && this.props.reducer.indexOf('customer_fiscal_contacts') === 0) console.log(this.props.reducer + " - lastSearch 147 - " + this.lastSearch);
    } else if (this.props.values && this.props.values.length > 0 && this.props.reducer && this.props.apiReducer && this.props.apiReducer[this.props.reducer] && this.props.apiReducer[this.props.reducer].status === 'VALID') {
      if (this.lastSearch === null) {
        this.lastSearch = -1;
      }
      this.idleTimeout = null;
      //if (this.props.reducer && this.props.reducer.indexOf('customer_fiscal_contacts') === 0) console.log(this.props.reducer + " - lastSearch 153 - " + this.lastSearch);
    }

    if (nextState.modalAdd !== this.state.modalAdd) {
      return true;
    }

    //if (nextProps.reducer && nextProps.reducer.indexOf('customer_fiscal_contacts') === 0) console.log("PIPPOOOOOO lastSearch:" + this.lastSearch + " nextProps.value:" + nextProps.value);
    const nextDValue = (nextState.currentDisplayedValue && nextState.currentDisplayedValue.length > 0) ? nextState.currentDisplayedValue : null;
    const prevDValue = (this.state.currentDisplayedValue && this.state.currentDisplayedValue.length > 0) ? this.state.currentDisplayedValue : null;
    if (nextDValue !== prevDValue) {
      return true;
    }

    //if (this.props.reducer && this.props.reducer.indexOf('customer_fiscal_contacts') === 0) console.log(this.props.reducer + " - lastSearch 156 - " + nextProps.value + "--" + this.lastSearch);
    if ((nextProps.value && nextProps.value !== this.lastSearch || (this.state.disabled && !nextState.disabled))
      && (!nextProps.values || nextProps.values.length === 0)
      && (nextProps.route || nextProps.reducer)) {
      //if (nextProps.reducer && nextProps.reducer.indexOf('customer_fiscal_contacts') === 0) console.log("PIPPOOOOOO call startSearch 1");
      this.startSearch(nextProps.fieldReturned, nextProps.value ?? this.lastSearch, nextProps, 1);
      //if (this.props.reducer && this.props.reducer.indexOf('customer_fiscal_contacts') === 0) console.log(this.props.reducer + " shouldComponentUpdate 2 false");
      return false;
    }

    const prevPValue = (this.props.value && (this.props.value.length > 0 || parseInt(this.props.value) > 0)) ? this.props.value : null;
    if (prevPValue === this.lastSearch && nextState.forced && this.props.onValueChanged) {
      const event = {
        target: {
          name: this.props.name,
          value: nextState.currentValue,
        },
      };

      if (this.props.onValueChanged) this.props.onValueChanged(event);
      //if (this.props.reducer && this.props.reducer.indexOf('customer_fiscal_contacts') === 0) console.log(this.props.reducer + " shouldComponentUpdate 3 false");
      return false;
    }

    const nextValues = (nextProps.values && nextProps.values.length > 0) ? nextProps.values : null;
    const prevValues = (this.props.values && this.props.values.length > 0) ? this.props.values : null;
    if (!isEqual(nextValues, prevValues)) {
      //if (this.props.reducer && this.props.reducer.indexOf('customer_fiscal_contacts') === 0) console.log(this.props.reducer + " shouldComponentUpdate 4 true");
      return true;
    }

    if (nextState.showIt !== this.state.showIt) {
      //if (this.props.reducer && this.props.reducer.indexOf('customer_fiscal_contacts') === 0) console.log(this.props.reducer + " shouldComponentUpdate 5 true");
      return true;
    }
    //if (this.props.reducer && this.props.reducer.indexOf('customer_fiscal_contacts') === 0) console.log(this.props.reducer + " shouldComponentUpdate 6 false");
    return false;
  }

  componentDidMount() {
    //if ((this.props.route || this.props.reducer) && ((!this.props.values || this.props.values.length === 0 || (this.props.values.length === 1 && this.props.values[0][this.props.fieldReturned] === 'null')) || (this.props.reducer.includes('related') && this.props.customerAssociated))) {
    if ((this.props.route || this.props.reducer) && (!this.props.values || this.props.values.length === 0 || (this.props.values.length > 0 && !this.props.values.find(obj => obj[this.props.fieldReturned] === this.props.value)))) {
      if (this.props.value && Object.keys(this.props.value).length > 0) {
        //if (this.props.reducer && this.props.reducer.indexOf('customer_fiscal_contacts') === 0) console.log("PIPPOOOOOO call startSearch 2");
        this.startSearch(this.props.fieldReturned, this.props.value, this.props, 1);
      } else {
        //if (this.props.reducer && this.props.reducer.indexOf('customer_fiscal_contacts') === 0) console.log("PIPPOOOOOO call startSearch 3");
        this.startSearch(this.props.fieldDisplayed, -1, this.props, 1);
      }
    }
  }

  onToggleModal(target) {
    this.setState({
      modalAdd: !this.state.modalAdd,
      disabled: !this.state.modalAdd,
      errorsModal: null,
    });
  }

  setAutocompleteOptionsPosition() {
    let positionString = '';
    const elementHeight = 300;
    const bottomPosition = (this.UiAutocompleteOptions) ? this.UiAutocompleteField.getBoundingClientRect().top + elementHeight : 0;
    if (bottomPosition < window.innerHeight) {
      positionString = 'open-bottom';
    } else {
      positionString = 'open-top';
    }
    this.setState({ positionString });
  }

  handleOnChange(value) {
    if (!this.state.disabled) {
      this.setAutocompleteOptionsPosition();
      const event = {
        target: {
          name: this.props.name,
          value,
        },
      };

      if (this.idleTimeout) {
        this.lastValue = value;
      } else if (this.props.onChange) {
        this.props.onChange(event);
      }

      let currentDisplayedValue = null;
      if (value && this.state.values) {
        if (this.props.fieldReturned) {
          const objValue = this.state.values.find((obj) => '' + obj[this.props.fieldReturned] === '' + value);
          if (objValue) {
            currentDisplayedValue = objValue[this.props.fieldDisplayed];
          }
        } else {
          if (this.props.fieldDisplayed) {
            currentDisplayedValue = value[this.props.fieldDisplayed];
          } else {
            currentDisplayedValue = value;
          }
        }
      }
      this.setState({
        showIt: false,
        currentDisplayedValue,
        touched: true,
        invalid: !event.target.value && this.props.required,
      });

      if (this.props.onBlur && !this.idleTimeout) {
        setTimeout(() => {
          this.props.onBlur(event);
        }, 500);
      }
    }
  }

  handleOnBlur(event) {
    if (this.props.fieldReturned) {
      const oldValue = this.state.currentValue;
      const objValue = (oldValue) ? this.state.values.find((obj) => '' + obj[this.props.fieldReturned] === '' + oldValue) : null;
      const newValue = (objValue && objValue[this.props.fieldDisplayed] === this.state.currentDisplayedValue) ? oldValue : null;
      this.setState({
        showIt: false,
        currentValue: newValue,
        currentDisplayedValue: (objValue && objValue[this.props.fieldDisplayed] === this.state.currentDisplayedValue) ? this.state.currentDisplayedValue : null,
        touched: true
      });
      if (oldValue !== newValue) {
        this.handleOnChange(null);
      }
    }
  }

  handleAutocompleteChange(event) {
    if (!this.state.disabled) {
      this.setAutocompleteOptionsPosition();

      let showIt = true;
      const currentDisplayedValue = (event.target.value) ? event.target.value : '';
      let currentValue = null;
      let stateCurrentValue = (this.state.currentValue) ? this.state.currentValue : null;

      if (this.state.values && currentDisplayedValue) {
        const objValue = this.state.values.find((obj) => '' + obj[this.props.fieldDisplayed] === '' + currentDisplayedValue);
        if (objValue) {
          currentValue = objValue[this.props.fieldReturned];
          showIt = (this.props.values && this.props.values.length && this.props.values.length > 0);
        }
      } else if (!currentDisplayedValue) {
        showIt = (this.props.values && this.props.values.length && this.props.values.length > 0);
      }

      if (this.UiAutocompleteList) {
        this.UiAutocompleteList.scrollTop = 0;
      }
      this.setState({
        showIt,
        currentDisplayedValue,
        currentSuggestion: 0,
      });

      if (stateCurrentValue !== currentValue || currentValue && !currentDisplayedValue) {
        if (currentValue) {
          this.handleOnChange(currentValue);
          return;
        }
        if (currentDisplayedValue.length === 0) {
          this.handleOnChange(null);
        }
      }

      if ((this.props.route || this.props.reducer) && ((currentDisplayedValue && this.lastSearch !== currentDisplayedValue) || (!currentDisplayedValue && this.lastSearch !== -1))) {
        //if (this.props.reducer && this.props.reducer.indexOf('customer_fiscal_contacts') === 0) console.log("PIPPOOOOOO call startSearch 4");
        this.startSearch(this.props.fieldDisplayed, (currentDisplayedValue) ? currentDisplayedValue : -1, this.props, 300);
      }
    }
  }

  startSearch(fieldname, lastSearch, props, timeout) {
    if (this.lastSearch === lastSearch) {
      return;
    }
    this.lastSearch = lastSearch;
    //if (this.props.reducer && this.props.reducer.indexOf('customer_fiscal_contacts') === 0) console.log("PIPPOOOOOO startSearch! lastSearch:" + this.lastSearch);
    let searchEvent = {
      target: {
        fieldname,
        search: lastSearch,
        bindValue: props.bindValue,
        envContext: props.envContext
      },
    };
    if (!!props.route && props.route !== 'filter' && (!props.reducer || (props.reducer.indexOf('_related') < 0 && props.reducer.indexOf('_building') < 0 && props.reducer.indexOf('_address') < 0 && props.reducer.indexOf('_place') < 0 && props.reducer.indexOf('loans') < 0))) {
      searchEvent.target.route = props.route;
    } else {
      searchEvent.target.reducer = props.reducer;
    }

    if (timeout > 1 && this.idleTimeout !== null) clearTimeout(this.idleTimeout);

    this.idleTimeout = setTimeout(() => {
      //if (this.props.reducer && this.props.reducer.indexOf('customer_fiscal_contacts') === 0) console.log("PIPPOOOOOO Search:" + searchEvent.target.search);
      if (props.onSearchChange) props.onSearchChange(searchEvent);
      this.idleTimeout = null;
      if (timeout > 1) {
        if (this.lastValue !== undefined) {
          this.handleOnChange(this.lastValue);
          this.lastValue = undefined;
        }
      }
    }, timeout);
  }

  handleCloseOptionsDiv() {
    if (!this.state.disabled) {
      const newState = cloneDeep(this.state);
      newState.showIt = false;
      newState.currentDisplayedValue = newState.oldCurrentDisplayedValue || null;
      newState.invalid = (this.props.required ? (!newState.currentValue) : false);
      if (!this.state.touched && this.state.showIt) newState.touched = true;
      this.setState(newState, () => this.UiAutocompleteInput && this.UiAutocompleteInput.current && this.UiAutocompleteInput.current.blur());
    }
  }

  handleCloseOnMouseOut(event) {
    if (this.state.showIt && this.UiAutocompleteField && !this.UiAutocompleteField.contains(event.target)) {
      event.stopPropagation();
      this.handleCloseOptionsDiv();
    }
  }

  handleKeyPress(event) {
    if (!this.state.disabled) {
      if (this.state.showIt) {
        const { key } = event;
        let value = null;
        let maxSuggestionsIndex = 0;
        switch (key) {
          case 'Enter':
            event.preventDefault();
            if (this.state.showIt && this.UiAutocompleteOption) {
              value = this.UiAutocompleteOption.getAttribute('value');
            } else {
              const objValue = this.state.values.find((obj) => '' + obj[this.props.fieldDisplayed] === '' + event.target.value);
              if (objValue) {
                value = objValue[this.props.fieldReturned];
              }
            }
            if (value) {
              this.handleOnChange(value);
            }
            break;
          case 'ArrowDown':
            event.preventDefault();
            maxSuggestionsIndex = (this.state.values && this.state.values.length > 0) ? this.state.values.length - 1 : 0;
            this.setState((prevState) => ({
              showIt: true,
              currentSuggestion: (!prevState.showIt ? 0 : (this.state.currentSuggestion < maxSuggestionsIndex) ? prevState.currentSuggestion + 1 : (prevState.currentSuggestion || 0)),
            }), () => {
              if (this.UiAutocompleteOption) this.UiAutocompleteList.scrollTop = this.UiAutocompleteOption.offsetTop - (this.UiAutocompleteOption.getBoundingClientRect().height * 2);
              else this.UiAutocompleteList.scrollTop = 0;
            });
            break;
          case 'ArrowUp':
            event.preventDefault();
            this.setState((prevState) => ({
              showIt: true,
              currentSuggestion: (!prevState.showIt ? 0 : (this.state.currentSuggestion > 0) ? prevState.currentSuggestion - 1 : (prevState.currentSuggestion || 0)),
            }), () => {
              if (this.UiAutocompleteOption) this.UiAutocompleteList.scrollTop = this.UiAutocompleteOption.offsetTop - (this.UiAutocompleteOption.getBoundingClientRect().height * 2);
              else this.UiAutocompleteList.scrollTop = 0;
            });
            break;
          case 'Escape':
            this.handleCloseOptionsDiv();
            break;
          default:
            if (this.UiAutocompleteInput && this.UiAutocompleteInput.current) this.UiAutocompleteInput.current.focus();
            break;
        }
      }
    }
  }

  toggleOptionsDiv(event) {
    event.preventDefault();
    const lastShowIt = this.state.showIt;
    this.setState((prevState) => ({
      showIt: !prevState.showIt,
      invalid: this.props.required ? this.state.touched && (!!(prevState.showIt && !prevState.currentValue)) : null,
      currentSuggestion: 0,
    }), () => {
      if (this.UiAutocompleteOption) this.UiAutocompleteList.scrollTop = this.UiAutocompleteOption.offsetTop - (this.UiAutocompleteOption.getBoundingClientRect().height * 2);
      else this.UiAutocompleteList.scrollTop = 0;
      if (lastShowIt) {
        if (this.UiAutocompleteInput && this.UiAutocompleteInput.current) this.UiAutocompleteInput.current.blur();
      } else {
        if (this.UiAutocompleteInput && this.UiAutocompleteInput.current) this.UiAutocompleteInput.current.focus();
      }
    });
  }

  handleOnMouseDown(currentItem, event) {
    if (!this.state.disabled) {
      if (event && event.target && event.target.className && event.target.className.indexOf && event.target.className.indexOf('UiAutocompleteList') === 0 && event.target.className.indexOf('show') > 0) {
        event.preventDefault();
        return;
      }
      const { target } = event;
      if (this.UiAutocompleteField && this.UiAutocompleteField.contains(target)) {
        if (!target.className.baseVal && target.className.indexOf('autocomplete-option') >= 0) {
          event.preventDefault();
          if (!target.className.includes('empty')) {
            if (target.className.includes('action')) {
              const creationModel = (this.props.envContext) ? (this.props.envContext) : {};
              creationModel[this.props.fieldDisplayed] = this.state.currentDisplayedValue;
              this.setState({ creationModel });
              this.onToggleModal('modalAdd');
            } else {
              if (this.props.fieldReturned) {
                this.handleOnChange(target.getAttribute('value'));
              } else {
                this.handleOnChange(currentItem)
              }
            }
          }
        }
      }
    }
  }

  handleOnFocus() {
    if (!this.state.disabled) {
      if (this.state.values && this.state.values.length > 0 && !this.state.showIt) {
        this.setState({ showIt: true });
      }
    }
  }

  onAddItem(clearRoute, creationModel) {
    const genericService = SiteMapService.getReducersMapByRoute()[clearRoute].service.getInstance(ApplicationStore);

    function okCallback(success) {
      this.onToggleModal('modalAdd');
      this.handleOnChange(success.data[this.props.fieldReturned]);
      this.startSearch(this.props.fieldReturned, success.data[this.props.fieldReturned], this.props, 1)
      toast.success(i18n.t('Common.success'));
    }

    function koCallback(error) {
      let errors = [];
      if (!!error && !!error.data && error.data.errors) {
        errors = error.data.errors;
      } else {
        let err = ObjectsUtils.buildError(error);
        errors.push(err);
      }
      this.setState({ loadingModal: false, errorsModal: errors });
    }

    genericService.addItem(creationModel, '', this.props.currentUser, okCallback.bind(this), koCallback.bind(this));
  }

  renderCreateModal(t, clearRoute) {
    const route = (this.props.envCreationRoute) ? this.props.envCreationRoute : clearRoute;
    console.log(this.props.currentUser);

    const fields = cloneDeep(TableMapService.getTableMapByGroup(this.props.currentUser.userGroup)[route]);
    Object.keys(this.state.creationModel).forEach((key) => {
      if (key !== this.props.fieldDisplayed && key !== 'date_start' && key !== 'date_end') {
        if (fields.find((obj) => obj.name === key)) {
          fields.find((obj) => obj.name === key).readonly = true;
        }
      }
    });

    return (
      <UiCreationsModal errorsModal={this.state.errorsModal} title={t(`Common.add_${clearRoute}`)}
        model={this.state.creationModel} isOpen={this.state.modalAdd} fields={fields}
        apiReducer={this.props.apiReducer} currentUser={this.props.currentUser}
        onSearchChange={this.props.onCreateSearchChange ? this.props.onCreateSearchChange.bind(this) : this.props.onSearchChange.bind(this)}
        onSubmit={this.onAddItem.bind(this, clearRoute)} onCancel={this.onToggleModal.bind(this, 'modalAdd')} />
    );
  }

  render() {
    const displayedVal = (this.state.currentDisplayedValue) ? this.state.currentDisplayedValue : '';
    let clearRoute = (this.props.route && this.props.route !== 'filter') ? this.props.route : null;
    if (clearRoute && clearRoute.indexOf('_') > 0) {
      clearRoute = (clearRoute.indexOf('_related') > 0) ? clearRoute.substring(0, clearRoute.indexOf('_related')) : clearRoute;

      clearRoute = clearRoute.replace('_tmp', '');

      clearRoute = clearRoute.replace(/[0-9]/g, '');
    }

    let siteRoute = Object.values(this.siteMap).find(obj => obj.sectionId === clearRoute) || {};
    let scope = (siteRoute.sectionParent) ? siteRoute.sectionParent : siteRoute.sectionId;

    let optionList = [];

    if (this.state.values && this.state.values.length > 0) {
      optionList = this.state?.values.filter((current) => {
        if (!this.props.fieldDisplayed && current?.toLowerCase()?.includes(displayedVal.toLowerCase())) {
          return true;
        }
        if (current[this.props.fieldDisplayed] && current[this.props.fieldDisplayed].toLowerCase().includes(displayedVal.toLowerCase())) {
          return true;
        }
        return false;
      }).map((current, k) => {
        return (
          <li role="presentation" key={`autocomplete-option-${this.props.name}-${k}`} className={`autocomplete-option${this.state.currentSuggestion === k ? ' sugerence' : ''}`}
            ref={(UiAutocompleteOption) => {
              this.state.currentSuggestion === k ? this.UiAutocompleteOption = UiAutocompleteOption : null;
            }}
            value={(this.props.fieldReturned) ? current[this.props.fieldReturned] : current}
            onMouseDown={this.handleOnMouseDown.bind(this, current ? current : {})} >
            {(this.props.fieldDisplayed) ? current[this.props.fieldDisplayed] : current}
          </li>
        )
      });
    }
    //if (this.props.reducer && this.props.reducer.indexOf('customer_fiscal_contacts') === 0) console.log("optionList " + this.state.values?.length);

    const valueAlreadyIn = (this.state.values && this.state.values.length > 0)
      ? ((this.props.fieldDisplayed)
        ? (this.state.values.find((obj) => '' + obj[this.props.fieldDisplayed] === '' + displayedVal) !== undefined)
        : (this.state.values.indexOf(displayedVal) >= 0))
      : false;

    if (displayedVal && clearRoute && !valueAlreadyIn) {
      optionList.push(
        <li key={`progeress-bar-${this.props.name}-1`} role="presentation" action="add" className="autocomplete-option action" onMouseDown={this.handleOnMouseDown.bind(this, {})}>
          {i18n.t('SelectValues.add_new_type_suggestion', { type: i18n.t(`Section.${clearRoute}`), suggestion: displayedVal })}
        </li>,
      );
    }

    //if (this.props.reducer && this.props.reducer.indexOf('customer_fiscal_contacts') === 0) console.log("SHOW-IT RENDER " + this.state.showIt);

    return (
      <I18n ns="translations">
        {t => (
          <div className={`UiAutocomplete${this.state.invalid && this.state.touched ? ' is-invalid' : ''}`}>
            {this.props.label ? (
              <CFormLabel htmlFor={this.props.name} className="UiMainLabel">
                {t(this.props.label)} {this.props.required ? '*' : ''}
                {this.props.tooltipMsg && (
                  <span>
                    <CTooltip placement="top" content={t(this.props.tooltipMsg)}>
                      <CIcon icon="cis-info-circle" size="sm" />
                    </CTooltip>
                  </span>
                )}
              </CFormLabel>
            ) : (
              ''
            )}
            {!!this.props.value && (this.props.value !== -1) && !!clearRoute && this.props.section !== 'academy' && (
              <small className="text-muted ml-3">
                {this.props.currentUser && this.props.currentUser.scopes.indexOf(`${scope}_view`) >= 0 && (
                  <Link to={`/${(this.props.baseurl) ? this.props.baseurl : clearRoute}/${this.props.value}`}>
                    <CIcon icon="cis-link" />
                  </Link>
                )}
              </small>
            )}
            {/* (this.state.values || this.state.currentDisplayedValue) && */}
            {!this.state.loading && (
              <div className="UiAutocompleteGroup" ref={UiAutocompleteField => (this.UiAutocompleteField = UiAutocompleteField)}>
                <CFormInput type={this.props.type} ref={this.UiAutocompleteInput}
                  id={this.props.name} className={`UiAutocompleteField ${(this.state.showIt && this.state.positionString) ? ` ${this.state.positionString}` : ''}${this.state.invalid && this.state.touched ? ' is-invalid' : ''}`}
                  name={this.props.name} disabled={this.state.disabled}
                  title={this.props.title} placeholder={(this.state.disabled) ? '' : (this.props.placeholder) ? t(this.props.placeholder) : `${t('Common.start_typing')}...`}
                  required={this.props.required} value={displayedVal} autoComplete="off" invalid={this.props.invalid} feedbackInvalid={t(this.props.feedbackInvalid)}
                  onFocus={this.handleOnFocus.bind(this)} onChange={this.handleAutocompleteChange.bind(this)} onBlur={this.handleOnBlur.bind(this)} />
                {!this.state.disabled && !this.props.invalid && (
                  <div role="presentation" className="UiDropDownIcon UiAutocomplete_toggler" onClick={(event) => this.toggleOptionsDiv(event)}>
                    <CIcon icon="cis-chevron-bottom" className="UiAutocomplete_toggler_icon" />
                  </div>
                )}
                <div className={`UiAutocompleteList ${this.state.positionString ? this.state.positionString : ''} ${this.state.showIt ? 'show' : ''}`}
                  ref={UiAutocompleteList => (this.UiAutocompleteList = UiAutocompleteList)}>
                  {optionList.length > 0 && (
                    <>
                      <ul className="autocomplete-list" ref={UiAutocompleteOptions => (this.UiAutocompleteOptions = UiAutocompleteOptions)} >
                        {optionList}
                      </ul>
                      {this.lastSearch === -1 && (optionList.length === ConstantsService.pageForAutocomplete) && (
                        <div>
                          <UiLoading type="bubbles" height={15} width={40} />
                          <div className="typeForMore mt-4">{t('Common.start_typing_for_more')}</div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {!!this.state.creationModel && clearRoute && this.renderCreateModal(t, clearRoute)}
              </div>
            )}
            {/*! this.state.values && !this.state.currentDisplayedValue && */}
            {this.state.loading && (
              <div className="UiAutocomplete_loader">
                <UiLoading type="bubbles" height={80} width={40} />
              </div>
            )}
            {this.state.invalid && this.state.touched && (
              <div className="invalid-feedback">{t('Common.field_invalid')}</div>
            )}
          </div>
        )}
      </I18n>
    );
  }
}

UiAutocomplete.propTypes = propTypes;

export default UiAutocomplete;