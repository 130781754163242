
import React from 'react';
import PropTypes from 'prop-types';

//UI
import { CHeaderToggler, CHeaderBrand, CHeaderNav, CImage, } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';

//COMPONENTS
import DefaultHeaderDropdown from './DefaultHeaderDropdown';

import logo from '../../../../images/newLogos/logo-people-first-white.svg';
//import logo from '../../../../images/logo.png';
//import logoMin from '../../../../images/logo-min.png';

//TYPES
import { userType } from '../../../types';
import { useState, useEffect } from 'react';

const propTypes = {
  currentUser: userType,
  currentSection: PropTypes.object,
  toggleAside: PropTypes.func,
  toggleSidebar: PropTypes.func,
  showSidebar: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const defaultProps = {};

function DefaultHeader(props) {
  const [currentUser, setCurrentUser] = useState({})

  //this useEffect simulate getDerivedStateFromProps
  useEffect(() => {
    setCurrentUser(props.currentUser)
  }, [props])

  const toggleSidebar = () => {
    // const val = [true, 'responsive'].includes(this.props.showSidebar) ? false : 'responsive';
    props.toggleSidebar(!props.showSidebar);
  };

  // toggleSidebarMobile = () => {
  //   // const val = [false, 'responsive'].includes(this.props.showSidebar) ? true : 'responsive';
  //   this.props.toggleSidebar(!this.props.showSidebar);
  // };

  return (
    <>
      <CHeaderToggler onClick={() => toggleSidebar()}>
        <CIcon className="me-2" size="lg" icon="cis-hamburger-menu" />
      </CHeaderToggler>
      {/* <CHeaderBrand className="mx-auto d-lg-none" to="/">
          <CImage src={logo} width="48" fluid alt="Logo" />
        </CHeaderBrand> */}
      <CHeaderBrand className={`me-2 ${props.showSidebar ? 'd-sm-none' : ''}`}>
        <CImage src={logo} fluid width="100" alt="Logo" />
      </CHeaderBrand>
      {/* <CHeaderToggler className="d-md-none" onClick={() => this.toggleSidebar()}>
          <CIcon className="me-2" size="lg" icon="cis-hamburger-menu" />
        </CHeaderToggler> */}
      {/* <CHeaderNav className="d-md-none me-auto">
          <CHeaderNavItem className="px-3">
            <CHeaderNavLink to="/">{i18n.t('Section.Dashboard')}</CHeaderNavLink>
          </CHeaderNavItem>
          <CHeaderNavItem className="px-3">
            <CHeaderNavLink to="#/users">{i18n.t('Section.Settings')}</CHeaderNavLink>
          </CHeaderNavItem>
        </CHeaderNav> */}
      <CHeaderNav className="ms-lg-auto">
        {/*<DefaultHeaderDropdown notif currentUser={currentUser} />*/}
        {/* <DefaultHeaderDropdown mssgs /> */}
        {/*  <CHeaderNavItem className="d-md-none">
            <CHeaderNavLink to="#">
              <CIcon icon="cis-location-pin" />
            </CHeaderNavLink>
          </CHeaderNavItem> */}
        {props.currentLanguage && (
          <DefaultHeaderDropdown
            langs
            currentUser={currentUser}
            currentLanguage={props.currentLanguage}
            changeLanguage={props.changeLanguage}
          />
        )}
        {currentUser && (
          <DefaultHeaderDropdown
            accnt
            currentUser={currentUser}
            isBlocking={props.isBlocking}
          />
        )}
      </CHeaderNav>
      {/*props.hasAside && (
        <CHeaderToggler onClick={props.toggleAside} className="d-none d-lg-inline">
          <CIcon className="me-2 " size="lg" icon="cis-applications-settings" />
        </CHeaderToggler>
      )*/}
    </>
  );
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
