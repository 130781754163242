import React, { useState } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
const defaultProps = {};


function DefaultFooter(props) {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  return (
    <>
      <div className='footer-container'>
        <span>
          <a href="https://www.peoplefirst.ws/it/">People First</a> &copy; {currentYear}.
        </span>
        <span className="ms-auto">
          Powered by <a href="https://www.peoplefirst.ws/it/">People First</a>
        </span>
      </div>
    </>
  );
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter
